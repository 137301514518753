<template>
  <div class="mobile-credential">
    <div class="headback">
      <div class="wrapper">
        <router-link to="/mobile/account" class="cvr-bg-bf">Login</router-link>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of headback -->
    <div id="login-register" class="holder">
      <div class="wrapper">
        <div class="form-basic">
          <form @submit.prevent="login">
            <div class="fgroup">
              <label>Email</label>
              <input type="email" v-model="email" required />
            </div>
            <!-- end of fgroup -->
            <div class="fgroup">
              <label>Password</label>
              <div class="pass-field">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  v-model="password"
                  required
                />
                <b></b>
              </div>
              <!-- end of pass field -->
              <div class="fgroup">
                <div
                  class="checky"
                  style="text-align: center; color: red; margin-top: 10px"
                >
                  <p v-for="(error, index) in errorMessage" :key="index">
                    {{ error }}
                  </p>
                </div>
              </div>
              <!-- end of group -->
              <router-link to="/mobile/forgot-password" class="forgot">
                Forgot your password?
              </router-link>
            </div>
            <!-- end of fgroup -->
            <div class="fgroup">
              <div class="checky">
                <label>
                  <input type="checkbox" v-model="rememberMe" />
                  <span>Remember Me</span>
                </label>
              </div>
              <button type="submit" class="button">Login</button>
            </div>
            <!-- end of group -->
          </form>
        </div>
        <!-- end of form basic -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of holder -->

    <div class="info">
      <div class="wrapper">
        <p>
          Don’t have an account yet?
          <router-link to="/mobile/register">Register</router-link>
        </p>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of info -->
  </div>
  <!-- end of mobile register -->
</template>

<script>
import cookie from "js-cookie";
import $ from "jquery";

export default {
  name: "Login",
  data() {
    return {
      email: null,
      password: null,
      rememberMe: false,
      errorMessage: []
    };
  },
  mounted() {
    $(".form-basic .pass-field b").on("click", function() {
      var self = $(this),
        target = self.closest(".pass-field").find("input");

      if (target.attr("type") == "password") {
        target.attr("type", "text");
        self.addClass("merem");
      } else {
        target.attr("type", "password");
        self.removeClass("merem");
      }
    });
  },
  methods: {
    login() {
      this.$axios
        .post(`/auth/login`, {
          email: this.email,
          password: this.password,
          isAdmin: false
        })
        .then(response => {
          let userdata = response.data.data;
          if (userdata.account.user === undefined) {
            userdata.account.user = userdata.account.admin;
          }
          this.$store.commit("SET_USERDATA", userdata);
          cookie.set("rubii", userdata, { expires: 1 });
          this.$router.push("/mobile/account");
        })
        .catch(error => {
          if (error.response.data.code == 422) {
            this.errorMessage = error.response.data.data.map(
              error => error.message
            );
          } else if (error.response.data.code == 400) {
            this.errorMessage.push(error.response.data.message);
          }
        });
    }
  }
};
</script>
